import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import AdSense from "react-adsense"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import {
  faChevronLeft,
  faChevronRight,
  // faCheckSquare
} from "@fortawesome/free-solid-svg-icons"

export default ({ data, pageContext, location }) => {
  const title = `${data.airtableArticles.data.titleJa} - ${data.airtableArticles.data.titleEn}`
  const contentJa = data.airtableArticles.data.contentJa.split("||||")
  const contentEn = data.airtableArticles.data.contentEn.split("||||")
  let contents = []
  for (let i = 0; i < contentJa.length; i++) {
    contents.push({ ja: contentJa[i], en: contentEn[i], id: i })
  }

  const imageFile = data.airtableArticles.data.eyecatch
    ? data.airtableArticles.data.eyecatch.localFiles[0]
    : null

  const eyecatchImageSharp = imageFile ? imageFile.childImageSharp : null

  const findCategoryBySlug = slug => {
    const { node } = data.allAirtableCategories.edges.find(
      ({ node }) => node.data.slug === slug
    )
    return node.data
  }

  return (
    <Layout>
      <SEO
        pagetitle={title}
        pagedesc={`${data.airtableArticles.data.contentJa.slice(0, 70)}...`}
        pagepath={location.pathname}
        blogimg={`${imageFile ? imageFile.publicURL : null}`}
        pageimgw={eyecatchImageSharp ? eyecatchImageSharp.original.width : null}
        pageimgh={
          eyecatchImageSharp ? eyecatchImageSharp.original.height : null
        }
      />
      <article className="content">
        <div className="container">
          <aside className="info">
            <div className="cat">
              <FontAwesomeIcon icon={faFolderOpen} />
              <ul>
                {data.airtableArticles.data.tags.map(tag => {
                  const category = findCategoryBySlug(tag)

                  return (
                    <li key={tag}>
                      <Link to={`/${category.path}`}>{category.nameJa}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </aside>

          <h1 className="">{title}</h1>

          {eyecatchImageSharp && (
            <div className="eyecatch mb-5">
              <figure className="overflow-hidden">
                <Img
                  fluid={eyecatchImageSharp.fluid}
                  alt={data.airtableArticles.data.titleJa}
                  className="w-full object-cover"
                  loading="eager"
                  durationFadeIn={50}
                />
              </figure>
            </div>
          )}

          <AdSense.Google
            client="ca-pub-2223665761819643"
            slot="6435117441"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />

          <div className="postbody">
            {contents.map(content => (
              <div key={content.id}>
                <p className="mb-3" role="alert" lang="en-US">
                  {content.en}
                </p>
                <p className="bg-gray-200 p-3" lang="ja-JP">
                  {content.ja}
                </p>
              </div>
            ))}
          </div>

          <AdSense.Google
            client="ca-pub-2223665761819643"
            slot="6435117441"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />

          <ul className="postlink">
            {pageContext.next && (
              <li className="prev">
                <Link
                  to={`/${pageContext.next.data.tags.join("/")}/${
                    pageContext.next.data.slug
                  }`}
                  rel="prev"
                  className="text-xl"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>{pageContext.next.data.titleJa}</span>
                </Link>
              </li>
            )}
            {pageContext.previous && (
              <li className="next">
                <Link
                  to={`/${pageContext.previous.data.tags.join("/")}/${
                    pageContext.previous.data.slug
                  }`}
                  rel="next"
                  className="text-xl"
                >
                  <span>{pageContext.previous.data.titleJa}</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    dummyImg: file(relativePath: { eq: "dummy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    airtableArticles(id: { eq: $id }) {
      data {
        contentEn
        contentJa
        slug
        tags
        titleEn
        titleJa
        eyecatch {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 315) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                height
                width
                src
              }
            }
            publicURL
          }
        }
      }
    }
    allAirtableCategories {
      edges {
        node {
          data {
            nameJa
            path
            slug
          }
        }
      }
    }
  }
`
